
// INFO: STYLES SEEMS TO BE UNUSED

.popup-licenceplate {
  font-size: 2em;
  margin: -2px 0px 6px 0px;
}

.marker-popup-wrapper {
  width: 350px;
  overflow: auto;
  font-family: var(--header-font);

  margin-bottom: 20px;
  margin-left: -25px;
}
.marker-popup-wrapper::before {
  content: "";
  background: url("./assets/popup-shadow.png") no-repeat bottom right;
  background-size: contain;
  width: 200px;
  height: 80%;
  position: absolute;
  bottom: 35px;
  left: -225px;
  opacity: 0.55;
  pointer-events: none;
}

.marker-popup-sideline {
  float: left;
  // background-color: #dce6f2;
  background-color: var(--colors-on-secondary-100);
  width: 18px;
  position: absolute;
  top: 0;
  left: -25px;
  bottom: 24px;
  color: black;
  writing-mode: vertical-lr;
  line-height: 1.32em;
  padding: 5px 0px 20px 0px;
  font-size: 1em;
  overflow: hidden;
  font-family: var(--header-font);
  text-overflow: ellipsis;
}
.marker-popup-sideline::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 13px;
  bottom: 0px;
  left: 0px;
  background: url("assets/popup-arrow-down.png") no-repeat center;
  background-size: contain;
  background-color: #0f354d;
}

.marker-popup-mainline {
  float: right;
  width: 87%;
  background-color: var(--colors-secondary-100);
  padding: 2% 3%;
}
.marker-popup-mainline span {
  color: #3a628e;
  font-size: 1.2em;
  font-family: var(--header-font);
  padding-right: 5px;
}
.marker-popup-mainline h1 {
  font-size: 2em;
  margin: 0px 0px;
  margin-bottom: 10px;
}
.marker-popup-controls {
  position: fixed;
  right: 0;
  top: 0;
  padding: 8px 5px;
  background-color: #002a38;
}
.marker-popup-controls i {
  font-size: 1.8em;
  vertical-align: middle;
  padding: 0px 3px;
  cursor: pointer;
}
.marker-popup-controls i:hover {
  color: #f31d64;
}

.marker-popup-bottomline {
  margin-top: 6px;
  float: right;
  width: 93%;
  height: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  // backdrop-filter: blur(10px);
  transition: all 0.5s;
  margin-bottom: 15px;
  overflow: hidden;
}
.marker-popup-wrapper:hover .marker-popup-bottomline,
.marker-popup-wrapper:active .marker-popup-bottomline {
  padding: 2% 0%;
  height: 70px;
  // transition-delay:1s;
}

.marker-popup-value {
  width: calc(15% - 1px);
  border-right: 1px dashed #ffffff36;
  height: 100%;
  display: inline-block;
  padding: 0px 5%;
}
.marker-popup-value .value {
  font-size: 4em;
  line-height: 1.1em;
}
.marker-popup-value .unit {
  font-size: 1.5em;
  vertical-align: top;
  line-height: 1.4em;
}

.mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup-content {
  box-shadow: none;
  background: transparent;
  padding: 0px;
}

.arrow-marker {
    display: block;
    transform: scale(0);
    font-size: 17.5px;
    font-weight: bold;
    cursor: pointer;
}

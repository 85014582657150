@use 'mapbox-gl/dist/mapbox-gl.css';

.cursor-crosshair {
  .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: crosshair !important;
  }
}

drip-map {
  * {
    box-sizing: border-box;
  }
}
